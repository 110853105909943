export const whyOwnUpLenderNetworkData = [
  {
    header: 'Increase loan volume',
    points: [
      'Turnkey solution for launching a Direct to Consumer channel',
      'Onboarding takes less than two weeks',
      'Originate loans for digital natives, the fastest growing borrower demographic'
    ]
  },
  {
    header: 'Improve profitability',
    points: [
      'Significantly decrease your cost to originate',
      'Own Up fee = 40 bps, due only after closing',
      'Pull through matches the national average for top Mortgage Loan Originators'
    ]
  },
  {
    header: 'Join an exclusive network of mortgage lenders',
    points: [
      'Own Up handpicks lending partners',
      'The number of lenders is limited by geography',
      'Own Up offers account management and competitive data to improve lender performance'
    ]
  }
];
